<template>
  <div class="myCollection-container">
      <div class="myCollection-box">
         <div class="myCollection-box-top">
             <div class="myCollection-box-top1">
                 <div :class="collectinActive">
                     <span class="span1" @click="collectionChange('you')">我的收藏</span>
                     <!-- <span class="span2" @click="collectionChange('wu')">无商标类别</span> -->
                 </div>
             </div>
             <div @click="del" class="myCollection-box-top2">删除</div>
         </div>
         <div class="myCollection-bigbox">
             <div class="myCollection-list" v-for="(v,k) in historyData" :key="k">
                <div class="myCollection-checkbox">
                    <div>
                        <el-checkbox v-bind:checked="v.isSelect" v-model="v.isSelect" class="myCollection-checkbox-input"></el-checkbox>
                    </div>
                </div>
                <div class="myCollection-img">
                    <img :src="v.trademarkImgUrl" alt="">
                </div>
                <div class="myCollection-inf">
                    <div class="myCollection-name">{{v.trademarkName}}</div>
                    <div class="myCollection-bigtype">
                        <div class="myCollection-bigtype-list" v-for="(item,index) in v.trademarkBody" :key="index">
                            <div class="myCollection-list-detail">
                                第{{item.num}}类 {{item.name}}
                            </div>
                            
                            <div v-for="(a,b) in item.second" class="myCollection-list-biglittle" :key="b">
                                <div class="myCollection-second">{{a.num}}{{a.name}}</div>
                                <div class="myCollection-list-little" v-for="(m,n) in a.three" :key="n">
                                    <span class="little-span" >{{m.name}}</span>
                                    <!-- <span>收起</span> -->
                                </div>
                                <div style="clear:both;"></div>
                            </div>
                            <div style="clear:both;"></div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
         <!-- <div class="myCollection-use" @click="useData">使用</div> -->
      </div>
      <div style="clear:both;"></div>
  </div>
</template>

<script>
export default {
  name: 'myCollection',
  data(){
      return{
          collectinActive:'you',
          historyData:[],
          all:[]
      }
  },
  components: {
  },
  watch:{
      
  },
  methods:{
      collectionChange(val){
          this.collectinActive = val;
      },
      del(){
        var prolist = this.historyData.filter(function (val) { return val.isSelect})
        if(prolist.length != 0){
            this.$confirm('确定要删除该类别吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            var code = [];
            for(var i = 0;i<prolist.length;i++){
                code.push(prolist[i].trademarkHisColCode)
            }
            
              this.$http.get(this.GLOBAL.new_url+'/trademarkhiscoll/webapi/delete',{params:{
                   codes:code.join(",")
              },headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }}).then( res => {
              if(res.data.code == '200'){
                  this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getdata();
              }
            })
            
            }).catch(() => {
                
            });
        }
      },
    //   useData(){
    //       var prolist = this.historyData.filter(function (val) { return val.isSelect});
    //       var newArr = []
    //       if(prolist.length != 0){
    //           for(var i = 0;i<prolist.length;i++){
    //               for(var j =0;j<prolist[i].trademarkBody.length;j++){
    //                   newArr.push(prolist[i].trademarkBody[j])
    //               }
                  
    //           }
    //       }
    //       this.all = this.all.map((x, y) => {
    //                     return x = {
    //                     num: x.num,
    //                     name: x.name,
    //                     isSelect1:true,
    //                     chose:0,
    //                     price:0,
    //                     sec: x.second.map((a, b) => {
    //                         return a = {
    //                         num: a.num,
    //                         code: a.id,
    //                         name: a.name,
    //                         isSelect2:true,
    //                         third: a.three.map((i, o) => {
    //                         return i = {
    //                         code: i.id,
    //                         num: i.num,
    //                         name: i.name,
                         
    //                         };
    //                         })
    //                         };
    //                     })
    //                     };
    //                     });
    //       console.log(newArr)
    //   },
    //   dataFilter(data){
    //         let arr = []
    //         data.map(i=>{
    //             let findIndex = arr.findIndex(_i=>_i.id === i.id);
    //             if(findIndex === -1) { // 没有重复
    //             arr.push(i)
    //             }else{
    //             let childKey
    //             if('second' in i) childKey = 'second';
    //             if('three' in i) childKey = 'three';
    //             if(!childKey) return false; //没有子级
    //             let child = [...arr[findIndex][childKey],...i[childKey]];
    //             arr[findIndex][childKey] = this.dataFilter(child)
    //             }
    //         })
    //         this.all = arr
    //         return arr
    //         },
      getdata(){
          this.$http.post(this.GLOBAL.new_url+'/trademarkhiscoll/webapi/list/pc',{
                        trademarkHisColType:2,
                        // keyWord:this.searchkey
                    },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                                   console.log(res)
                                    if(res.data.code == '200'){
                                       this.historyData = res.data.data;
                                       var _this=this;
                                        this.historyData.map((x,y)=>{
                                            _this.$set(x, 'isSelect', false);
                                            x.trademarkBody = JSON.parse(x.trademarkBody);
                                            
                                            x.trademarkBody.map((a,b)=>{
                                            _this.$set(a, 'isSelect1', false);  
                                            })
                                        })
                                    }
                                })
      }
  },
  mounted(){
      this.getdata()
  }
}

</script>

<style scoped>
    .myCollection-box{
        width:1015px;
        float: left;
        margin: 12px 0 0 16px;
        min-height: 1100px;
        background-color: #fff;
        padding: 32px 30px 50px;
        box-sizing: border-box;
    }
   .myCollection-box-top{
       height: 32px;
   }
    .myCollection-box-top>div:nth-of-type(1){
        float: left;
    }
    .myCollection-box-top1>div>span{
        display: inline-block;
        width: 70px;
        height: 27px;   
        font-size: 14px;
        color:#B7B7B7;
        font-weight: 400;
        margin-right: 40px;
        cursor: pointer;
    }
    .you .span1,.wu .span2{
        /* border-bottom:1px solid #000000; */
        font-weight: 500;
        color: #000000;
    }
    .myCollection-box-top2{
        float: right;
        width: 64px;
        height: 24px;
        text-align: center;
        background: #FBEFE9;
        border-radius: 3px;
        cursor: pointer;
        line-height: 24px;
        font-size: 14px;
        font-weight: 400;
        color: #F96009;
    }
    .myCollection-bigbox{
        margin-bottom: 50px;
    }
    .myCollection-list{
        border-top:1px solid #F2F2F2;
        padding: 40px 0 30px;
        overflow: hidden;
    }
    .myCollection-list>div{
        float: left;
    }
    .myCollection-checkbox{
        width: 16px;
        height: 120px;
        margin-right: 40px;
    }
    /* .myCollection-checkbox>div{
        width: 16px;
        height: 120px;
    } */
    .myCollection-checkbox-input{
        width: 16px;
        height: 16px;
        margin-top: 52px;
        cursor: pointer;
    }
    .myCollection-img{
        width: 120px;
        height: 120px;
        border:1px solid #D8D8D8;
        margin-right: 40px;
    }
    .myCollection-img>img{
        width: 120px;
        height: 120px;
    }
    .myCollection-inf{
        width: 660px;
    }
    .myCollection-name{
        font-size: 18px;
        margin-bottom: 18px;
        font-weight: 500;
        color: #000000;
        line-height: 25px;
    }
    .myCollection-bigtype{
        width: 660px;
        /* border:1px solid red; */
    }
    .myCollection-bigtype-list{
        width:660px;
        margin-bottom: 20px;
    }
    .myCollection-list-detail{
      font-size: 14px;
      color: #000000;
      margin-bottom: 12px;
    }
    .myCollection-list-detail>i{
      margin-left: 5px;
      cursor: pointer;
    }
    .myCollection-list-biglittle{
        /* float: left; */
    }
    .myCollection-second{
        margin-bottom: 10px;
        font-size: 14px;
    }
    .myCollection-list-little{
        float: left;
        height: 22px;
        margin-bottom: 10px;
    }
    .little-span{
        display: inline-block;
       padding: 0 9px;
       height: 22px;
       border: 1px solid #d8d8d8;
       margin: 0 10px 4px 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        color:#A1A1A1;
    }
    .myCollection-list-little>span:last-child{
        color: #F96009;
        cursor: pointer;
        font-size: 14px;
    }
    .myCollection-use{
        float: right;
        width: 214px;
        height: 54px;
        background: #F96009;
        border-radius: 3px;
        cursor: pointer;
        font-size: 18px;
        color: #FFFFFF;
        text-align: center;
        line-height: 54px;
    }
</style>
<style>
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #EB5E00;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #EB5E00;
    border-color: #EB5E00;
  }
</style>



